import { useContext } from 'react'

import BumpCheckoutContext from '../components/Bumps/containers/BumpCheckoutContext'

function useBumpCheckoutContext() {
  const bumpCheckoutProps = useContext(BumpCheckoutContext)

  if (!bumpCheckoutProps) throw new Error('Missing Bump checkout provider')

  return bumpCheckoutProps
}

export default useBumpCheckoutContext
