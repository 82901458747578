'use client'

import { useState } from 'react'
import { Dialog } from '@vinted/web-ui'

import { ResponseError } from '@marketplace-web/shared/api-client'
import { ResponseCode } from 'data/api/response-codes'

import BumpOrderShadowBannedError from './ErrorModals/BumpOrderShadowBannedError'
import BumpOrderItemDeletedError from './ErrorModals/BumpOrderItemDeletedError'

type Props = {
  bumpOptionsError: ResponseError
}

const BumpOrderErrorModal = ({ bumpOptionsError }: Props) => {
  const prepareResponseCode = bumpOptionsError.code

  const [show, setShow] = useState(true)

  const handleClose = () => setShow(false)

  const renderModalContent = () => {
    switch (prepareResponseCode) {
      case ResponseCode.ActionRestricted:
        return <BumpOrderShadowBannedError handleClose={handleClose} />

      default:
        return <BumpOrderItemDeletedError handleClose={handleClose} />
    }
  }

  return (
    <Dialog show={show} testId="bump-order-error-modal">
      {renderModalContent()}
    </Dialog>
  )
}

export default BumpOrderErrorModal
