'use client'

import { Button, Cell, Image, Spacer, Text } from '@vinted/web-ui'
import { ReactNode } from 'react'

import { useIsDarkMode } from '@marketplace-web/shared/dark-mode'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { CDN_IMAGES_URL } from '@marketplace-web/shared/ui-helpers'
import { INBOX_URL } from 'constants/routes/inbox'

type Props = {
  handleClose: () => void
}

const BumpOrderShadowBannedError = ({ handleClose }: Props) => {
  const isDarkMode = useIsDarkMode()

  const translate = useTranslate(
    'extra_services.checkout.order_status_modal.push_up_order.shadow_banned',
  )

  const darkModeSuffix = isDarkMode ? '_dark' : ''
  const iconPath = `${CDN_IMAGES_URL}/landing/advertising/cross-circle${darkModeSuffix}.svg`

  const inboxLink = {
    inbox: (chunks: Array<ReactNode>) => (
      <Text as="span" width={Text.Width.Parent}>
        <a href={INBOX_URL} target="_blank" rel="noreferrer">
          {chunks}
        </a>
      </Text>
    ),
  }

  return (
    <Cell>
      <Spacer size={Spacer.Size.Large} />
      <div className="u-flexbox u-justify-content-center">
        <Image src={iconPath} size={Image.Size.Large} testId="bump-failure-icon" alt="" />
      </div>
      <Spacer size={Spacer.Size.X2Large} />
      <Text
        as="h2"
        text={translate('title')}
        alignment={Text.Alignment.Center}
        width={Text.Width.Parent}
        type={Text.Type.Heading}
      />
      <Spacer size={Spacer.Size.Large} />
      <Text as="span" text={translate('explanation')} width={Text.Width.Parent} />
      <Spacer size={Spacer.Size.X2Large} />
      <Text as="span" text={translate('learn_more', inboxLink)} width={Text.Width.Parent} />
      <Spacer size={Spacer.Size.X2Large} />
      <Button
        text={translate('action')}
        styling={Button.Styling.Filled}
        onClick={handleClose}
        testId="continue-button"
      />
    </Cell>
  )
}

export default BumpOrderShadowBannedError
