'use client'

import { Fragment } from 'react'
import { Badge, SelectionGroup, Spacer, Text } from '@vinted/web-ui'

import { useAbTest, useTrackAbTest } from '@marketplace-web/shared/ab-tests'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { List, SeparatedList } from '@marketplace-web/shared/ui-helpers'
import { BumpOptionModel } from 'types/models/bump'

import BumpOptionListItem from './BumpOptionListItem'

type Props = {
  selectedOptionId: string
  bumpOptions: Array<BumpOptionModel>
  isLoading: boolean
  onSelect: (selectedOption: BumpOptionModel) => void
}

const BumpOptionList = ({ onSelect, bumpOptions, selectedOptionId, isLoading }: Props) => {
  const translate = useTranslate('bump_items.selection_confirmation_modal')

  const selectionGroupAbTest = useAbTest('bumps_precheckout_group_selection_v2')
  const isSelectionGroupAbTestEnabled =
    selectionGroupAbTest?.variant !== 'off' && selectionGroupAbTest
  useTrackAbTest(selectionGroupAbTest)

  const handleOptionSelect = (option: BumpOptionModel) => () => {
    onSelect(option)
  }

  if (isSelectionGroupAbTestEnabled) {
    const renderTitle = (option: BumpOptionModel) => {
      const { description, isBestValue } = option

      return (
        <SeparatedList separator={<Spacer />}>
          {isBestValue && (
            <Badge
              theme="success"
              content={
                <Text
                  as="span"
                  theme="inverse"
                  type={Text.Type.Caption}
                  text={translate('duration.best_value_badge_text')}
                />
              }
            />
          )}
          <Text as="span" type={Text.Type.Title} text={description} />
        </SeparatedList>
      )
    }

    const renderBody = (body: string | undefined) => {
      if (!body) return null

      return <Text as="span" type={Text.Type.Caption} text={body} />
    }

    return (
      <SelectionGroup layout={SelectionGroup.Layout.Default} styling={SelectionGroup.Styling.Tight}>
        {bumpOptions.map(option => (
          <SelectionGroup.SelectionItem
            key={option.id}
            title={renderTitle(option)}
            body={renderBody(option.countries)}
            onClick={handleOptionSelect(option)}
            isSelected={option.id === selectedOptionId}
            testId={`bump-option-${option.days}-day-${option.international ? 'international' : 'local'}`}
            showSelectionIndicator={selectionGroupAbTest.variant === 'a'}
          />
        ))}
      </SelectionGroup>
    )
  }

  return (
    <List>
      {bumpOptions.map(option => (
        <Fragment key={option.id}>
          <BumpOptionListItem
            bumpOption={option}
            onSelect={handleOptionSelect(option)}
            isSelected={option.id === selectedOptionId}
            isLoading={isLoading}
          />
        </Fragment>
      ))}
    </List>
  )
}

export default BumpOptionList
