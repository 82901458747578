'use client'

import { useEffect, useState } from 'react'

import {
  PromoAfterItemUpload,
  usePromoAfterItemUploadContext,
} from '@marketplace-web/domain/profile'
import { useFetch } from '@marketplace-web/shared/api-client'
import {
  getLocalStorageItem,
  navigateToPage,
  setLocalStorageItem,
} from '@marketplace-web/shared/browser'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { UiState } from '@marketplace-web/shared/ui-helpers'
import { urlWithParams } from '@marketplace-web/shared/utils'
import { viewSingleCheckoutEvent } from '_libs/common/event-tracker/events'
import { navigateToSingleCheckout } from '_libs/utils/checkout'

import { CheckoutOrderTypeMap, ExtraServiceOrderType } from 'constants/extra-service'
import { BUMP_MULTIPLE_ITEM_SELECTION_URL } from 'constants/routes'

import { initiateSingleCheckout, prepareBumpOrder } from 'data/api'
import { transformPrepareBumpOrderResponse } from 'data/api/transformers/response/transform-prepare-bump-order-response'
import GenericErrorModal from 'pages/SingleCheckout/components/GenericErrorModal'
import { BumpableItemPreCheckoutModel } from 'types/models/bump'

import { PreCheckoutModal } from '../../../constants/bumps'
import useBumpCheckoutContext from '../../../hooks/useBumpCheckoutContext'
import BumpOrderErrorModal from './BumpOrderErrorModal'
import BumpSelectionConfirmationModal from './BumpSelectionConfirmationModal'
import BumpValuePropositionModal from './BumpValuePropositionModal'
import useBumpOptions from './hooks/useBumpOptions'

const VALUE_PROPOSITION_SHOWN_KEY = 'push_up_value_proposition_shown'

type Props = {
  isInitiatedFromMultiSelect?: boolean
}

const BumpCheckout = ({ isInitiatedFromMultiSelect = false }: Props) => {
  const { track } = useTracking()
  const { selectedItems, isPreCheckoutOpen, setIsPreCheckoutOpen, setSelectedItems } =
    useBumpCheckoutContext()

  const { uploadedItemId, currentPromotion, setCurrentPromotion } = usePromoAfterItemUploadContext()

  const [uiState, setUiState] = useState(UiState.Idle)
  const [activeModal, setActiveModal] = useState<PreCheckoutModal>(PreCheckoutModal.None)

  const selectedItemIds = selectedItems.map(item => item.id)

  const {
    fetch: prepareOrder,
    transformedData: order,
    error: orderError,
  } = useFetch(prepareBumpOrder, transformPrepareBumpOrderResponse)

  const {
    bumpOptions,
    isBumpOptionsLoading,
    bumpOptionsError,
    selectedBumpOption,
    updateSelectedBumpOption,
  } = useBumpOptions(activeModal)

  useEffect(
    function openPreCheckout() {
      if (!isPreCheckoutOpen) return

      const isValuePropositionSeen = getLocalStorageItem(VALUE_PROPOSITION_SHOWN_KEY) === 'true'

      if (!isValuePropositionSeen) {
        setLocalStorageItem(VALUE_PROPOSITION_SHOWN_KEY, 'true')
        setActiveModal(PreCheckoutModal.ValueProposition)

        return
      }

      setActiveModal(PreCheckoutModal.OptionSelection)
    },
    [isPreCheckoutOpen],
  )

  useEffect(
    function openPrecheckoutAfterUploadForm() {
      if (currentPromotion !== PromoAfterItemUpload.BumpCheckout) return
      if (!uploadedItemId) return

      const selectedItem: BumpableItemPreCheckoutModel = {
        id: uploadedItemId,
        title: null,
        thumbnail: null,
      }

      setSelectedItems([selectedItem])
      setActiveModal(PreCheckoutModal.OptionSelection)
      setCurrentPromotion(PromoAfterItemUpload.Empty)
    },
    [currentPromotion, uploadedItemId, setActiveModal, setCurrentPromotion, setSelectedItems],
  )

  useEffect(
    function setErrorModal() {
      if (!orderError) return

      setUiState(UiState.Failure)

      setActiveModal(PreCheckoutModal.GenericErrorModal)
    },
    [orderError, setActiveModal],
  )

  useEffect(() => {
    const orderId = order?.id
    const orderType = CheckoutOrderTypeMap[ExtraServiceOrderType.PushUp]

    if (!orderId) return

    track(
      viewSingleCheckoutEvent({
        orderType,
        checkoutId: null,
        orderId: orderId.toString(),
      }),
    )

    const fetchCheckoutId = async () => {
      const response = await initiateSingleCheckout({
        id: orderId.toString(),
        type: orderType,
      })

      if ('errors' in response) {
        setUiState(UiState.Failure)

        setActiveModal(PreCheckoutModal.GenericErrorModal)

        return
      }

      navigateToSingleCheckout(response.checkout.id, orderId, orderType)
    }

    fetchCheckoutId()
  }, [order, track, setActiveModal])

  function openSelectionConfirmationModal() {
    setActiveModal(PreCheckoutModal.OptionSelection)
  }

  function openValuePropositionModal() {
    setActiveModal(PreCheckoutModal.ValueProposition)
  }

  function goToBumpPage() {
    navigateToPage(urlWithParams(BUMP_MULTIPLE_ITEM_SELECTION_URL, { item_ids: selectedItemIds }))
  }

  function closeCheckoutModal() {
    setActiveModal(PreCheckoutModal.None)
    setIsPreCheckoutOpen(false)
  }

  function handleConfirmSelection() {
    if (!selectedBumpOption) return

    setUiState(UiState.Pending)

    prepareOrder(selectedItemIds, selectedBumpOption.days, selectedBumpOption.international)
  }

  if (bumpOptionsError) return <BumpOrderErrorModal bumpOptionsError={bumpOptionsError} />

  return (
    <>
      <BumpSelectionConfirmationModal
        show={activeModal === PreCheckoutModal.OptionSelection}
        isOrderLoading={uiState === UiState.Pending}
        bumpOptions={bumpOptions}
        isBumpOptionsLoading={isBumpOptionsLoading}
        selectedOption={selectedBumpOption}
        onAddButtonClick={isInitiatedFromMultiSelect ? closeCheckoutModal : goToBumpPage}
        onSelectedOptionChange={updateSelectedBumpOption}
        onConfirm={handleConfirmSelection}
        onCancel={closeCheckoutModal}
        onHelpButtonClick={openValuePropositionModal}
      />
      <BumpValuePropositionModal
        show={activeModal === PreCheckoutModal.ValueProposition}
        hasLocalOptions={bumpOptions?.hasLocalOptions}
        onCloseOrConfirm={openSelectionConfirmationModal}
      />
      <GenericErrorModal
        isShown={activeModal === PreCheckoutModal.GenericErrorModal}
        onClose={closeCheckoutModal}
      />
    </>
  )
}

export default BumpCheckout
