'use client'

import { createContext } from 'react'
import { noop } from 'lodash'

import { BumpableItemPreCheckoutModel } from 'types/models/bump'

export type BumpCheckoutContextType = {
  isPreCheckoutOpen: boolean
  selectedItems: Array<BumpableItemPreCheckoutModel>
  setIsPreCheckoutOpen: (isOpen: boolean) => void
  setSelectedItems: (items: Array<BumpableItemPreCheckoutModel>) => void
}

export const initialValues: BumpCheckoutContextType = {
  isPreCheckoutOpen: false,
  selectedItems: [],
  setIsPreCheckoutOpen: noop,
  setSelectedItems: noop,
}

const BumpCheckoutContext = createContext<BumpCheckoutContextType>(initialValues)

export default BumpCheckoutContext
